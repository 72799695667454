<template>
  <div class="container mx-auto">
    <div class="flex flex-wrap">
      <div class="px-4 w-full">
        <BigTitle title="Settings" class="mb-11 pt-40"/>
        
      </div>
    </div>
  </div>
</template>

<script>
// import Search from '@/components/settings/Search'
import BigTitle from '@/components/BigTitle'

export default {
  name: 'Settings',
  components: { BigTitle },
}
</script>

<style>

</style>